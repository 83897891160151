import { AdbDaemonWebUsbDevice } from '@yume-chan/adb-daemon-webusb';
import { Adb } from '@/api/adb/adb';
import { Log } from '@/api/log/log';

export enum LicenseType {
    Butterfly = 'com.butterflytx.bliss.home',
    Enozone = 'com.Enozone.bliss.vr.home'
}

export enum DeviceType {
    Phone = 'phone',
    PicoG2 = 'PICOA7910',
    PicoG3 = 'PICOA7Q10'
}

export const bootAnimationPath = '/storage/emulated/0/bootanimation/';

export class Wifi {
    enabled = false;
    connected = false;

    static parse(data: string): Wifi {
        const w = new Wifi();
        if (!!data && !data.startsWith("Can't find service")) {
            w.enabled = (data.match(/Wi-Fi is (enabled|disabled)/)![1]! ?? 'disabled') == 'enabled';
            w.connected =
                (data.match(/mNetworkInfo.*state: (CONNECTED|DISCONNECTED)/)![1]! ??
                    'DISCONNECTED') == 'CONNECTED';
        }
        return w;
    }
}

export class Battery {
    acPowered = false;
    usbPowered = false;
    wirelessPowered = false;
    chargeCounter = 0;
    status = 0;
    health = 0;
    present = false;
    level = 0;
    scale = 0;
    voltage = 0;
    temperature = 0;
    technology = '';

    get percent(): number {
        return (this.level * 100) / this.scale;
    }

    static parse(data: string): Battery {
        const b = new Battery();
        if (!!data && !data.startsWith("Can't find service")) {
            b.acPowered = (data.match(/AC powered: (true|false)/)![1]! ?? 'false') == 'true';
            b.usbPowered = (data.match(/USB powered: (true|false)/)![1]! ?? 'false') == 'true';
            b.wirelessPowered =
                (data.match(/Wireless powered: (true|false)/)![1]! ?? 'false') == 'true';
            b.chargeCounter = Number(data.match(/Charge counter: -?(\d+)/)![1]!) ?? 0;
            b.status = Number(data.match(/status: (\d+)/)![1]!) ?? 0;
            b.health = Number(data.match(/health: (\d+)/)![1]!) ?? 0;
            b.present = (data.match(/present: (true|false)/)![1]! ?? 'false') == 'true';
            b.level = Number(data.match(/level: (\d+)/)![1]!) ?? 0;
            b.scale = Number(data.match(/scale: (\d+)/)![1]!) ?? 0;
            b.voltage = Number(data.match(/voltage: (\d+)/)![1]!) / 10 ?? 0;
            b.temperature = Number(data.match(/temperature: (\d+)/)![1]!) / 10 ?? 0;
            b.technology = data.match(/technology: ([\w-]+)/)![1]! ?? '';
        }
        return b;
    }
}

export type License = {
    owner: string;
    usage: string;
    issued?: Date;
    expire?: Date;
};

export class Device {
    name: string;
    serial: string;
    deviceName = '';
    deviceAdmin = '';
    modelName = '';
    manufacturer = '';
    systemVersion = '';
    wifi = new Wifi();
    battery = new Battery();
    currentDate: Date = new Date(1970, 1, 1);
    source: AdbDaemonWebUsbDevice;
    packages: Package[] = [];

    constructor(name: string, serial: string, source: AdbDaemonWebUsbDevice) {
        this.name = name;
        this.serial = serial;
        this.source = source;
    }

    is(type: DeviceType): boolean {
        if (this.manufacturer.toUpperCase() == 'PICO') {
            return type.toString() == this.deviceName;
        }
        return type == DeviceType.Phone;
    }

    isInstalled(pkg: string) {
        return this.packages.findIndex((d) => d.name === pkg) > -1;
    }

    async loadInfo(): Promise<void> {
        const adb = new Adb(this.serial);
        try {
            await adb.connect();
            await this.loadDeviceInfo(adb);
        } finally {
            await adb.close();
        }
    }

    async reboot(opt = '') {
        const adb = new Adb(this.serial);
        try {
            await adb.connect();
            await adb.shell(`reboot ${opt}`.trim());
        } catch (e) {
            console.error(e);
        } finally {
            await adb.close();
        }
    }

    public async loadPackagesInfo(packages: string[]): Promise<void> {
        const adb = new Adb(this.serial);
        try {
            await adb.connect();
            const pp = this.packages.filter((p) => packages.indexOf(p.name) > -1);
            for (const p of pp) {
                const dump = await adb.shell(`dumpsys package ${p.name}`);
                p.setup(dump);
            }
        } catch (e) {
            console.error(e);
        } finally {
            await adb.close();
        }
    }

    public async getProps(adb: Adb, props: string): Promise<string | number> {
        const res = await adb.shell(`getprop ${props}`);
        if (!isNaN(Number(res))) return Number(res);
        return res;
    }

    public async setProps(adb: Adb, props: string, value: string | number) {
        await adb.shell(`setprop ${props} ${value}`);
    }

    public async getFiles(adb: Adb, path: string) {
        const res = await adb.shell(`ls ${path}`);
        if (res.endsWith('No such file or directory')) return [];
        return res
            .split(/\s+/g)
            .filter((f) => f != '')
            .sort((a, b) => (a < b ? -1 : 1));
    }

    async configureLauncher(adb: Adb, launcher: string, enabled: boolean) {
        if (enabled) {
            await adb.shell(`cmd package set-home-activity ${launcher}`);
        } else {
            await adb.shell(`am task lock stop ${launcher}`);
            const re = /Launcher: ComponentInfo\{([\w./]+)}/g;
            const res = await adb.shell(`adb shell cmd shortcut get-default-launcher`);
            const app = (re.exec(res) ?? ['', ''])[1];
            await adb.shell(`cmd package set-home-activity ${app}`);
        }
    }

    async configureDeviceAdmin(adb: Adb, rcv: string, active: boolean) {
        if (active) {
            await adb.shell(`dpm set-device-owner ${rcv}`);
            await adb.shell(`dpm set-active-admin ${rcv}`);
            this.deviceAdmin = rcv;
        } else {
            await adb.shell(`dpm remove-active-admin ${rcv}`);
            this.deviceAdmin = '';
        }
    }

    async startApp(adb: Adb, pkg: string) {
        const an = await adb.shell(`dumpsys package | grep -i ${pkg} | grep Activity | head -n 1`);
        const activity = an.replace(/\w+ ([\w\\./]+)/, '$1');
        await adb.shell(`am force-stop ${pkg}`);
        const res = await adb.shell(`am start -n ${activity}`);
        if (res.indexOf(`Error: Activity class {${activity}} does not exist`) > -1)
            throw 'Failure [ACTIVITY_DOES_NOT_EXIST]';
    }

    public async installBundle(
        adb: Adb,
        baseUrl: string,
        pkg: string,
        apks: string[],
        isTestOnly = false
    ) {
        const path = '/data/local/tmp/app';
        try {
            await adb.shell(`mkdir -p ${path}`);
            for (const apk of apks) {
                const url = `${baseUrl}/${apk}`;
                await adb.stream(url, path);
            }
            const res = await adb.shell(`ls -l ${path}`);
            const toInstall = res
                .split('\n')
                .filter((l) => l.indexOf('total'))
                .map((l) => {
                    const arr = l.split(new RegExp('\\s+', 'gi'));
                    return { size: Number(arr[4]), name: `${path}/${arr[7]}` };
                });
            if (toInstall.length != apks.length)
                throw `Missing file(s) to install (${toInstall.length} file found)`;
            const totalSize = toInstall.reduce((n, { size }) => n + size, 0);
            let cmd = `pm install-create -r -S ${totalSize}`;
            if (isTestOnly) cmd = `pm install-create -t -r -S ${totalSize}`;
            const sess = await adb.shell(cmd);
            const sessId = sess.match(/\d+/g)!.pop();
            if (sessId) {
                for (let i = 0; i < toInstall.length; i++) {
                    const install = toInstall[i];
                    await adb.shell(
                        `pm install-write -S ${install.size} ${sessId} ${i} ${install.name}`
                    );
                }
                const res = await adb.shell(`pm install-commit ${sessId}`);
                if (!res.startsWith('Success')) throw 'install failed';
            }
        } finally {
            await adb.shell(`rm -r ${path}`);
        }
    }

    public async uninstall(adb: Adb, pkg: string) {
        await adb.shell(`pm uninstall ${pkg}`);
    }

    public async setPermission(adb: Adb, pkg: string, permission: string) {
        await adb.shell(`pm grant ${pkg} ${permission}`);
    }

    protected async loadDeviceInfo(adb: Adb): Promise<void> {
        this.deviceName = await adb.shell('getprop ro.product.device');
        this.modelName = await adb.shell('getprop ro.product.model');
        this.manufacturer = await adb.shell('getprop ro.product.manufacturer');
        this.systemVersion = await adb.shell('getprop ro.build.display.id');
        const wifi = await adb.shell('dumpsys wifi');
        if (wifi.startsWith("Can't find service")) throw 'Not ready';
        this.wifi = Wifi.parse(wifi);
        const battery = await adb.shell('dumpsys battery');
        if (battery.startsWith("Can't find service")) throw 'Not ready';
        this.battery = Battery.parse(battery);
        const re = /\s+admin=ComponentInfo\{([\w./]+)}/g;
        const policy = await adb.shell('dumpsys device_policy');
        this.deviceAdmin = this.parseReceiver((re.exec(policy) ?? ['', ''])[1].trim());
        const date = await adb.shell("date '+%Y-%m-%d %H:%M:%S'");
        this.currentDate = new Date(Date.parse(date));
        const list = await adb.shell(`pm list packages --show-versioncode`);
        this.packages = list.split('\n').map((l) => new Package(l));
        if (this.packages.length == 0) throw 'Not ready';
    }

    private parseReceiver(receiver: string): string {
        if (!receiver) return receiver;
        const arr = receiver.split('/');
        const pkg = arr.shift()!;
        const rcv = arr.shift()!.split('.').pop()!;
        if (receiver == `${pkg}/${pkg}.${rcv}`) return `${pkg}/.${rcv}`;
        return receiver;
    }
}

export class VRDevice extends Device {
    protected readonly configNamePath = '/storage/emulated/0/setup.cfg';
    private _licenseUrl = '';
    private _licenseFileName = '';
    public currentConfig = '';
    public serverLicense = '';
    public deviceLicense = '';

    get hasServerLicense(): boolean {
        return !!this.serverLicense;
    }

    get hasDeviceLicense(): boolean {
        return !!this.deviceLicense;
    }

    get hasLicenseToUpdate(): boolean {
        return this.deviceLicense != this.serverLicense;
    }

    get hasTimeUpToDate() {
        return this.currentDate.toLocaleDateString() == new Date().toLocaleDateString();
    }

    get licenseUrl(): string {
        return this._licenseUrl;
    }

    get licenseFileName(): string {
        return this._licenseFileName;
    }

    async loadServerLicense(): Promise<void> {
        for (const ext of ['sign', 'pem']) {
            const url = `https://licence.bliss-dtx.com/files/license_${this.serial}.${ext}`;
            try {
                const resp = await fetch(url);
                this._licenseUrl = url;
                this._licenseFileName = `license.${ext}`;
                this.serverLicense = await resp.text();
            } catch (e) {
                console.error(e);
            }
        }
    }

    async writeConfigName(adb: Adb, content: string): Promise<void> {
        try {
            return this.writeToFile(adb, this.configNamePath, content);
        } catch (e) {
            console.error(e);
        }
    }

    async readConfigName(adb: Adb): Promise<void> {
        try {
            this.currentConfig = await adb.pull(this.configNamePath);
        } catch (e) {
            if (`${e}` != 'No such file or directory') console.error(e);
        }
    }

    protected async writeToFile(adb: Adb, path: string, content: string): Promise<void> {
        const fileName = path.split('/').pop()!;
        const blob = new Blob([content], { type: 'text/plain' });
        const file = new File([blob], fileName, { type: 'text/plain' });
        await adb.push(file, path);
    }

    protected async startAndWaitClose(
        adb: Adb,
        pkg: string,
        activity: string,
        args: { [k: string]: any } = {}
    ): Promise<void> {
        await adb.ensureScreenOn();
        await adb.shell(`am force-stop ${pkg}`);
        const parts = [`am start -W -S -n ${pkg}/.${activity}`];
        for (const arg in args) {
            parts.push(`--es ${arg} ${args[arg]}`);
        }
        await adb.shell(parts.join(' '));
        let running = true;
        do {
            const res = await adb.shell(`pidof ${pkg}`);
            running = res != '';
        } while (running);
        await adb.wait(100);
    }
}

export class PicoG2Device extends VRDevice {
    override async loadInfo(): Promise<void> {
        const adb = new Adb(this.serial);
        try {
            await adb.connect();
            await this.loadDeviceInfo(adb);
            await this.loadDeviceLicense(adb);
            await this.readConfigName(adb);
        } finally {
            await adb.close();
        }
        await this.loadServerLicense();
    }

    async loadDeviceLicense(adb: Adb): Promise<void> {
        const path = `/sdcard/Android/data/${LicenseType.Enozone}/files/license.sign`;
        try {
            this.deviceLicense = await adb.pull(path);
        } catch (e) {
            this.deviceLicense = '';
        }
    }

    async pushDeviceLicense(adb: Adb): Promise<boolean> {
        const path = `/sdcard/Android/data/${LicenseType.Enozone}/files`;
        await adb.shell(`rm -rf ${path}/${this.licenseFileName}`);
        const res = await adb.stream(this.licenseUrl, path, this.licenseFileName);
        await adb.wait(10);
        return res.indexOf('Success') > -1;
    }

    async setDatetime(adb: Adb): Promise<void> {
        const date = new Date();
        const datetime =
            date.toLocaleDateString('fr').replaceAll('/', '-') +
            ' ' +
            date.toLocaleTimeString('fr');
        await adb.shell(
            `am start-foreground-service -n com.blissdtx.maintenanceservice/.MaintenanceService --es date "${datetime}"`
        );
    }

    public override async getFiles(adb: Adb, path: string) {
        const res = await super.getFiles(adb, path);
        if (path == '/storage/emulated/0/Video360/WildImmersion') {
            res.unshift('[INTERNAL_ONLY]_Drop_SpecificWildVideoFile.bat');
        }
        return res;
    }

    get license(): License | null {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.deviceLicense, 'application/xml');
        // const ids = doc.querySelectorAll('details idList string');
        const values = doc.querySelectorAll('details valuesList string');
        // console.log(ids.item(0).textContent, values.item(0).textContent);
        const issued = new Date(
            Date.parse(values.item(2).textContent!.split('/').reverse().join('-'))
        );
        return {
            owner: values.item(0).textContent!,
            usage: values.item(4).textContent!,
            issued: issued
        };
    }
}

export class PicoG3Device extends VRDevice {
    public setupLog = '';

    override async loadInfo(): Promise<void> {
        const adb = new Adb(this.serial);
        try {
            await adb.connect();
            await this.loadDeviceInfo(adb);
            await this.loadDeviceLicense(adb);
            await this.readConfigName(adb);
            await this.readLogSetup(adb);
        } finally {
            await adb.close();
        }
        await this.loadServerLicense();
    }

    async loadDeviceLicense(adb: Adb): Promise<void> {
        const path = `/sdcard/Android/data/${LicenseType.Butterfly}/files/license.sign`;
        try {
            this.deviceLicense = await adb.pull(path);
        } catch (e) {
            this.deviceLicense = '';
        }
    }

    async readLogSetup(adb: Adb) {
        try {
            this.setupLog = await adb.pull('/storage/emulated/0/headsetsetup.log');
        } catch (e) {
            this.setupLog = '';
        }
    }

    async pushDeviceLicense(adb: Adb): Promise<boolean> {
        const path = `/sdcard/Android/data/${LicenseType.Butterfly}/files`;
        await adb.shell(`rm -rf ${path}/${this.licenseFileName}`);
        const res = await adb.stream(this.licenseUrl, path, this.licenseFileName);
        await adb.wait(10);
        return res.indexOf('Success') > -1;
    }

    async setDatetime(adb: Adb): Promise<void> {
        const pkg = 'com.butterflytx.maintenance';
        await adb.shell('settings put global auto_time 0');
        await this.startAndWaitClose(adb, pkg, 'MainActivity', { time_millis: Date.now() });
        await adb.wait(100);
        const res = await adb.shell('logcat -d');
        const lines = res.split('\n').filter((l) => l.indexOf('Maintenance: ') > -1);
        lines.forEach((l) =>
            Log.debug({ action: 'set-time', cmd: 'set-time', result: l, serial: this.serial })
        );
        const date = await adb.shell("date '+%Y-%m-%d %H:%M:%S'");
        this.currentDate = new Date(Date.parse(date));
    }

    async setupBlissOne(adb: Adb): Promise<void> {
        const pkg = 'com.butterflytx.bliss.home';
        await this.writeToFile(adb, '/storage/emulated/0/headsetsetup', 'setup');
        await this.startAndWaitClose(adb, pkg, 'HomePlayerActivity');
    }

    get license(): License {
        const json = JSON.parse(this.deviceLicense);
        const exp = Date.parse(
            json.apps['com.butterflytx.bliss.home'].expires.split('/').reverse().join('-')
        );
        return { owner: json.info.owner, usage: json.globalSettings.usage, expire: new Date(exp) };
    }
}

export class Package {
    readonly name: string;
    versionName = '';
    versionCode = 0;
    permissions: string[] = [];
    private dumpsys = '';

    constructor(raw: string) {
        if (raw.indexOf(' ') > -1) {
            const r = raw.split(' ');
            this.name = r[0].split(':')[1];
            this.versionCode = Number(r[1].split(':')[1]);
        } else {
            this.name = raw.split(':')[1];
        }
    }

    setup(dump: string): void {
        this.dumpsys = dump;
        this.versionName = this.getVersionName();
        this.permissions = this.getPermissions();
    }

    private getVersionName(): string {
        const m = this.dumpsys.match(/versionName=(.*)\n/);
        if (m && m.length > 1) return `v${m[1]}`;
        return 'v0.0.0';
    }

    private getPermissions(): string[] {
        const perms: string[] = [];
        if (!this.dumpsys) return perms;
        const ll = this.dumpsys.split('\n');
        for (const l of ll) {
            if (l.indexOf('.permission.') > -1) {
                if (l.indexOf(':') > -1) perms.push(l.split(':')[0].trim());
                else perms.push(l.trim());
            }
        }
        return perms;
    }
}
